var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "green-bg", attrs: { fluid: "" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                {
                  staticClass: "sm-and-down--flex-wrap md-and-up--flex-nowrap",
                  attrs: { "no-gutters": "", align: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pr-lg-2",
                      attrs: {
                        cols: "12",
                        lg: "8",
                        "order-md": "2",
                        order: "1",
                      },
                    },
                    [
                      _c(
                        "v-carousel",
                        {
                          staticClass: "mb-4 hero-slider",
                          attrs: {
                            "show-arrows": false,
                            "hide-delimiters": true,
                          },
                          model: {
                            value: _vm.activeHeroCat,
                            callback: function ($$v) {
                              _vm.activeHeroCat = $$v
                            },
                            expression: "activeHeroCat",
                          },
                        },
                        _vm._l(_vm.allCategories, function (hcat, i) {
                          return _c(
                            "v-carousel-item",
                            {
                              key: i,
                              attrs: { href: hcat.hero_url, fade: "" },
                            },
                            [
                              _c("img", {
                                staticClass: "d-flex responsive-img pa-2",
                                attrs: {
                                  src: _vm.$vuetify.breakpoint.lgAndUp
                                    ? "/" + hcat.home_images[0]
                                    : _vm.$vuetify.breakpoint.mdOnly
                                    ? "/" + hcat.home_images[1]
                                    : "/" + hcat.home_images[2],
                                },
                              }),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        md: "6",
                        lg: "6",
                        order: "2",
                        "order-md": "1",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "transparent", attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "text-h2 font-weight-medium text--nobreak",
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t("home.hero.title")) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-card-text", { staticClass: "text-h5" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("home.hero.description")) +
                                "\n                            "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "transparent px-lg-16 mx-lg-16",
                          attrs: { flat: "" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              placeholder: _vm.$t("searchWhat"),
                              clearable: "",
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                _vm.searchTerm.length > 2
                                  ? _vm.$router.push(
                                      "/" +
                                        _vm.$t("searchSlug") +
                                        "?q=" +
                                        _vm.searchTerm
                                    )
                                  : undefined
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-inner",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "mt-n1",
                                                    attrs: {
                                                      icon: "",
                                                      disabled: !_vm.searchTerm,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.searchTerm.length >
                                                        2
                                                          ? _vm.$router.push(
                                                              "/" +
                                                                _vm.$t(
                                                                  "searchSlug"
                                                                ) +
                                                                "?q=" +
                                                                _vm.searchTerm
                                                            )
                                                          : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            _vm.mdiMagnify
                                                          ) +
                                                          "\n                                                "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(_vm.$t("search")) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.searchTerm,
                              callback: function ($$v) {
                                _vm.searchTerm = $$v
                              },
                              expression: "searchTerm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "primary white--text pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-carousel",
            {
              staticClass: "shop-green",
              attrs: { "hide-delimiter-background": "", height: "100%" },
              model: {
                value: _vm.currentSlide,
                callback: function ($$v) {
                  _vm.currentSlide = $$v
                },
                expression: "currentSlide",
              },
            },
            _vm._l(_vm.slides, function (slide, i) {
              return _c(
                "v-carousel-item",
                { key: "slide" + i },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "fill-height",
                      attrs: {
                        "no-gutters": "",
                        justify: "center",
                        align: "center",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            lg: "4",
                            "offset-lg": "1",
                            order: "2",
                            "order-lg": "1",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "transparent py-10 py-lg-2 px-0 px-md-4 px-lg-0",
                              attrs: { flat: "" },
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "text-h2 font-weight-bold text--nobreak white--text",
                                },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(slide.title) +
                                      "\n                                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                { staticClass: "text-h5 mt-lg-5 white--text" },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(slide.description) +
                                      "\n                                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                { staticClass: "pl-4" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "font-weight-bold mt-lg-5 px-8 text-capitalize",
                                      attrs: {
                                        text: "",
                                        outlined: "",
                                        large: "",
                                        dark: "",
                                        color: "white",
                                        href: slide.cta.url,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(slide.cta.text) +
                                          "\n                                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            lg: "6",
                            "offset-lg": "1",
                            "order-lg": "2",
                            order: "1",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "transparent", attrs: { flat: "" } },
                            [
                              _c("v-img", {
                                staticClass: "shop-green__img",
                                attrs: {
                                  eager: i === 0,
                                  src: "/img/" + slide.image,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "grey-bg pa-md-0", attrs: { fluid: "" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "8", "offset-lg": "2" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "transparent text-lg-center",
                          attrs: { flat: "" },
                        },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "d-lg-block py-0" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t("home.categories.title.text")) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-card-text", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("home.categories.subtitle")) +
                                "\n                            "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "pa-0 pa-lg-4 md-and-down--container-fluid" },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "pb-5 md-and-down--px-5pct md-and-down--overflow-auto",
                  attrs: { "no-gutters": "" },
                },
                _vm._l(_vm.popular, function (category, i) {
                  return _c(
                    "v-col",
                    { key: i, attrs: { cols: "6", lg: "4", md: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "transparent text-center",
                          attrs: { flat: "", href: category.url },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              height: "200",
                              contain: "",
                              src: category.image,
                              alt: category.name,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "text-body-1 d-block font-weight-bold text--nobreak",
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(category.name) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "green-bg", attrs: { fluid: "" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "8", "offset-lg": "2" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "transparent text-lg-center",
                          attrs: { flat: "" },
                        },
                        [
                          _c("v-card-title", { staticClass: "d-lg-block" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("home.allCategories.title")) +
                                "\n                            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-card-subtitle", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("home.allCategories.subtitle")) +
                                "\n                            "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "pb-5", attrs: { "no-gutters": "" } },
                _vm._l(_vm.allCategories, function (category, i) {
                  return _c(
                    "v-col",
                    {
                      key: i,
                      staticClass: "px-2",
                      attrs: { cols: "6", md: "4" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "font-weight-bold mt-5 px-8 text-capitalize",
                          attrs: {
                            text: "",
                            outlined: "",
                            large: "",
                            block: "",
                            color: "primary",
                            href: "/" + _vm.$t("categorySlug") + category.url,
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(category.title) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "grey-bg pa-0 pa-lg-4", attrs: { fluid: "" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "transparent", attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "text-h4 font-weight-bold" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t("home.products.title")) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "pa-0 pa-lg-4 md-and-down--container-fluid" },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "pb-5 md-and-down--px-5pct md-and-down--overflow-auto",
                  attrs: { "no-gutters": "" },
                },
                _vm._l(_vm.topProducts, function (product, ipp) {
                  return _c(
                    "v-col",
                    {
                      key: product.id,
                      staticClass: "px-2 mb-4",
                      attrs: { cols: "6", md: "4", lg: "3" },
                    },
                    [
                      _c("product-card", {
                        attrs: {
                          product: product,
                          "current-page-index": ipp + 1,
                        },
                        on: { "open-product-overlay": _vm.openProductOverlay },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-overlay", {
        attrs: {
          "active-product": _vm.activeProduct,
          "related-loading": _vm.products.relatedLoading,
          "related-offers": _vm.relatedOffers,
        },
        model: {
          value: _vm.isProductOverlayActive,
          callback: function ($$v) {
            _vm.isProductOverlayActive = $$v
          },
          expression: "isProductOverlayActive",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }